import React from 'react';
import styled from 'styled-components';

import MenuBar from '../../components/home/MenuBar';
import Layout from '../../layouts/index';
import JobOfferWelcomeToTheJungle from '../../components/home/v2/JobOfferWelcomeToTheJungle';
import JobOfferBanner from '../../components/home/v2/JobOfferBanner';
import Footer from '../../components/home/Footer';
import Dots from '../../components/home/v2/Dots';
import {
  margins, Container, Content, Title2,
}
  from '../../components/home/v2/StyledComponents';

const StyledContent = styled(Content)`
  margin: ${margins.l} auto;
`;

const JobOfferPage = () => (
  <Layout slug="JobOfferPage">
    <Container>
      <MenuBar />
      <JobOfferBanner />
      <StyledContent>
        <Dots big />
        <Title2 big>Nos offres</Title2>
        <JobOfferWelcomeToTheJungle />
      </StyledContent>
      <Footer />
    </Container>
  </Layout>
);

export default JobOfferPage;
